import React from "react"
import { Container, Row, Card, CardBody, Col } from "reactstrap"


const CardsMini = () => {
  
  return (
    <React.Fragment>
      <section className="section bg-white p-0">
        <Container>
          <div className="currency-price">
            <Row>
              {/* reder card boxes */}
             
              <Col md="4">
                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="avatar-xs me-3">
                      <span
                          className={ "avatar-title rounded-circle font-size-18"}
                                          >
                          <i className="bx bxs-map-pin nav-icon d-block"/>
                        </span>
                      </div>
                      <div className="flex-grow-1">
                        <p className="text-muted">Tracciabilità del farmaco </p>
                        <h5>TRACCIABILITÀ</h5>
                        <p className="text-muted text-truncate mb-0">
                        MedStock consente di monitorare ogni<br></br>
                        dispositivo medico lungo tutto il<br></br>
                        suo ciclo di vita, tutelando <br></br>tutti gli interessati
                          <i
                            className="mdi mdi-check ms-1 text-success"
                          />
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="avatar-xs me-3">
                        <span
                          className={ "avatar-title rounded-circle font-size-18"}
                                          >
                         <i className="bx bx-happy nav-icon d-block"/>
                        </span>
                      </div>
                      <div className="flex-grow-1">
                        <p className="text-muted">Tranquillità</p>
                        <h5>CONFORMITA' NORMATIVA</h5>
                        <p className="text-muted text-truncate mb-0">
                        il mancato rispetto degli obblighi <br></br>
                        normativi potrebbe ricadere nelle<br></br>
                        esclusioni generali della <br></br>
                        polizza assicurativa
                          <i
                            className="mdi mdi-check ms-1 text-success"
                          />
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="avatar-xs me-3">
                        <span
                          className={ "avatar-title rounded-circle font-size-18"}
                                          >
                          <i className="bx bx-barcode nav-icon d-block"/>
                        </span>
                      </div>
                      <div className="flex-grow-1">
                        <p className="text-muted">Facilità</p>
                        <h5>GESTIONE EFFICACE</h5>
                        <p className="text-muted text-truncate mb-0">
                        MedStock permette una gestione<br></br> 
                        semplice, sicura ed efficace<br></br>  
                        del magazzino dei dispositi medici
                          <i
                            className="mdi mdi-check ms-1 text-success"
                          />
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>




            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default CardsMini
