import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody, Button} from "reactstrap";
import { Link } from "react-router-dom";

//Images
import client1 from "../../../assets/images/clients/1.png";
import client2 from "../../../assets/images/clients/2.png";
import client3 from "../../../assets/images/clients/3.png";
import client4 from "../../../assets/images/clients/4.png";
import client5 from "../../../assets/images/clients/5.png";
import client6 from "../../../assets/images/clients/6.png";

//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import "../../../../node_modules/swiper/swiper.scss";

const AboutUs = () => {

  return (
    <React.Fragment>
      <section className="section pt-4 bg-white" id="about">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">MedStock</div>
                <h4>Cos'è MedStock?</h4>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg="5">
              <div className="text-muted">
                <h4>La soluzione per la gestione del magazino UDI</h4>
                <p>
                Il Decreto del Ministero della Salute dell'11 maggio 2023 introduce l’obbligo di registrazione e conservazione dell’Identificativo Unico del Dispositivo (UDI) per tutte le istituzioni sanitarie e gli operatori sanitari.                
                </p> 
                <p>
                Se siete medici di libera professione, è necessario tenere traccia dei dispositivi medici venduti o somministrati ai pazienti e garantire la corretta gestione del vostro "magazzino personale".
                </p>
                <p>
                MedStock è l’applicazione in cloud progettata per semplificare e soddisfare gli obblighi di legge dei professionisti sanitari, che permette di:
                </p>
                <p>
                <ul>
                  <li>Conservare in modo sicuro e organizzato tutti i tracciati di magazzino</li>
                  <li>Registrare e gestire facilmente il codice UDI di ogni singolo dispositivo</li>
                  <li>Rispettare la normativa vigente con aggiornamenti costanti</li>
                </ul>
                </p>
                

                <div className="d-flex flex-wrap gap-2">
                  <Button href="#team" className="btn btn-success">
                    Contattaci
                  </Button>
                  <Button href="#faqs"  className="btn btn-light">
                    Scopri di più 
                  </Button>
                </div>
                {/*
                
                */}
              </div>
            </Col>

            <Col lg="6" className="ms-auto">
              <div className="mt-4 mt-lg-0">
                <Row>
                  <Col sm="6">
  
                    <Card className="border">
                      <CardBody>
                        <div className="mb-3">
                          <i className="mdi mdi-qrcode h1 text-success" />
                        </div>
                        <h5>Scansione UDI</h5>
                        <p className="text-muted mb-0">
                        Inserisci l’UDI e tutte le informazioni necessarie in pochi clic.
                        </p>
                        
                      </CardBody>
                     
                    </Card>
                  </Col>
                  <Col sm="6">
                    <Card className="border mt-lg-5">
                      <CardBody>
                        <div className="mb-3">
                          <i className="mdi mdi-table-sync h1 text-success" />
                        </div>
                        <h5>Magazzino</h5>

                        <p className="text-muted mb-0">
                        Cloud sicuro: I tuoi dati sono sempre protetti e accessibili ovunque tu sia.
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          {/*       
          
                */}
        </Container>
      </section>
    </React.Fragment>
  );
};

export default AboutUs;
